import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Services from "../services/global.service";
import { useParams } from "react-router-dom";
import "../assets/css/modal.scss";
import Arrow from "../assets/imgs/keyboard_arrow_right_24px.svg";

const AllFilterWithSearch = ({
  filterModal,
  setFilterModal,
  selectedOptions,
  setSelectedOptions,
  handleValider,
  count,
  setCount,
  selectedMarques,
  setSelectedMarques,
  selectedCategories,
  setSelectedCategories,
  listOption,
  listMarque,
  listCategorie,
}) => {
  const [categories, setCategories] = useState([]);
  const [options, setOptions] = useState([]);
  const [openCat, setOpenCat] = useState(false);
  const [openOptions, setOpenOptions] = useState(false); // State to control the visibility of options
  const [expandedCategories, setExpandedCategories] = useState([]);
  const params = useParams();
  const [initialSelectedOptions, setInitialSelectedOptions] = useState([]);
  const [cancelinitialSelectedOptions, setCancelInitialSelectedOptions] =
    useState(selectedOptions);
  const [initialSelectedCategories, setInitialSelectedCategories] = useState(
    []
  );
  const [initialCount, setInitialCount] = useState(count);
  const [resetTriggered, setResetTriggered] = useState(false);

  const getOptions = () => {
    try {
      const initialSelectedOptionsget = { marque: [], tri: [] };
      listOption?.forEach((option) => {
        initialSelectedOptionsget[option.option] = [];
      });
      const mergedOptions = [
        ...(listMarque.length > 0
          ? [
              {
                option: "marque",
                values: listMarque?.map((marque) => marque?.name) || [],
              },
            ]
          : []),
        {
          option: "categorie",
          values: listCategorie?.map((categorie) => categorie?.name) || [],
        },
        ...listOption,
      ];

      setInitialSelectedOptions(initialSelectedOptionsget);
      setOptions(mergedOptions);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // getCategories();
    getOptions();
  }, []);

  // const [selectedCategories, setSelectedCategories] = useState([]);

  const handleCategoryToggle = (categoryId) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(categoryId)
        ? prevSelected.filter((id) => id !== categoryId)
        : [...prevSelected, categoryId]
    );
  };

  const resetFilters = () => {
    setSelectedOptions(initialSelectedOptions);
    setSelectedCategories(initialSelectedCategories);
    setCount(0);
    setResetTriggered(true);
  };

  const handleCategoryExpandToggle = (categoryId) => {
    setExpandedCategories((prevExpanded) =>
      prevExpanded.includes(categoryId)
        ? prevExpanded.filter((id) => id !== categoryId)
        : [...prevExpanded, categoryId]
    );
  };

  const isCategorySelected = (categoryId) =>
    selectedCategories.includes(categoryId);

  const onHide = () => {
    setSelectedOptions(initialSelectedOptions);
    setSelectedCategories(initialSelectedCategories);
    setFilterModal(false);
  };

  const cancelFiltre = () => {
    setSelectedOptions(cancelinitialSelectedOptions);
    setSelectedCategories(initialSelectedCategories);
    setFilterModal(false);
    setCount(initialCount);
  };

  const handleSubOptionToggle = (mainOption, subOption) => {
    const myArray = [...(selectedOptions[mainOption] || [])];
    const index = myArray.indexOf(subOption);

    if (index !== -1) {
      myArray.splice(index, 1);
      setCount(count - 1);
    } else {
      myArray.push(subOption);
      setCount(count + 1);
    }

    setSelectedOptions({
      ...selectedOptions,
      [mainOption]: myArray,
    });
  };

  const isSelectedSubOption = (mainOption, subOption) => {
    return selectedOptions[mainOption]?.includes(subOption);
  };

  // useEffect(() => {
  //   if (filterModal) {
  //     setInitialSelectedOptions(selectedOptions);
  //     setInitialSelectedCategories(selectedCategories);
  //     setInitialCount(count);
  //   }
  // }, [filterModal]);

  useEffect(() => {
    if (resetTriggered) {
      handleValider();
      setResetTriggered(false);
    }
  }, [resetTriggered, handleValider]);

  return (
    <Modal show={filterModal} onHide={onHide} className="all-filter-modal">
      <Modal.Header closeButton>
        <h5>Tous les filtres</h5>
      </Modal.Header>
      <Modal.Body>
        {categories.length > 0 && (
          <div className="row">
            <div className="col-md-8">
              <h6>Catégories</h6>
            </div>
            <div
              onClick={() => {
                setOpenCat(!openCat);
              }}
              className={`col text-end ${openCat ? "openFilter" : ""}`}
            >
              <img src={Arrow} alt="arrow icon" />
            </div>
          </div>
        )}
        {openCat &&
          categories.map((category) => (
            <div key={category?.id}>
              <label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={isCategorySelected(category?.id)}
                  onChange={() => handleCategoryToggle(category?.id)}
                />
                {category?.name}
              </label>
              {category?.subcategories &&
                expandedCategories.includes(category?.id) && (
                  <ul>
                    {category?.subcategories?.map((subcategory) => (
                      <li key={subcategory.id}>
                        <label>
                          <input type="checkbox" className="form-check-input" />
                          {subcategory?.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              {category?.subcategories && (
                <div onClick={() => handleCategoryExpandToggle(category?.id)}>
                  <img src={Arrow} alt="arrow icon" />
                </div>
              )}
            </div>
          ))}

        <hr />
        {/* Options */}
        <div className="row mt-32">
          <div className="col-md-8">
            <h6 onClick={() => setOpenOptions(!openOptions)}>Options</h6>
          </div>
          <div
            onClick={() => setOpenOptions(!openOptions)}
            className={`col text-end ${openOptions ? "openFilter" : ""}`}
          >
            <img src={Arrow} alt="arrow icon" />
          </div>
        </div>

        {openOptions &&
          options.map((option) => (
            <div key={option?.id}>
              <label className="bleu700-body-s pb-16 bleu700">
                {option?.option}
              </label>
              {option?.values &&
                option.values.map((subOption, index) => (
                  <label className="container-check" key={index}>
                    <span className="label-check"> {subOption}</span>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={isSelectedSubOption(option?.option, subOption)}
                      onChange={() => {
                        handleSubOptionToggle(option?.option, subOption);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                ))}
            </div>
          ))}
        <div className="text-end mt-24">
          <button
            className="cta-primary-m mr-24"
            onClick={() => {
              handleValider();
              setFilterModal(false);
            }}
          >
            Afficher les résultats
          </button>
          <button className="cta-secondary-m mr-24" onClick={cancelFiltre}>
            Annuler
          </button>
          <button className="link-s" onClick={resetFilters}>
            Tout effacer
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AllFilterWithSearch;
