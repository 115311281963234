import { Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import "../assets/css/modal.scss";

const FilterOptionsWithSearch = ({
  openFiltreOptions,
  setOpenFiltreOption,
  selectedOptions,
  setCount,
  setSelectedMarques,
  setSelectedOptions,
  selectedMarques,
  handleDeleteOption,
  handleDeleteMarque,
}) => {
  const handleClose = () => {
    setOpenFiltreOption(false);
  };
  const resetFilters = () => {
    setSelectedMarques([]);
    setSelectedOptions([]);
    setCount(0);
  };

  useEffect(() => {}, []);
  return (
    <>
      <Modal
        show={openFiltreOptions}
        onHide={handleClose}
        className="modal-marion"
      >
        <Modal.Header closeButton>
          <h6>Filtres appliqués</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="row ">
            {Object.entries(selectedOptions).map(
              ([option, values]) =>
                values.length > 0 &&
                values.map(
                  (
                    value,
                    index // Add index as key
                  ) =>
                    value && (
                      <div
                        key={`${option}-${index}`}
                        className="selectedFilter mt-24 col-auto mr-16"
                      >
                        {" "}
                        {/* Use a combination of option and index as key */}
                        {value}{" "}
                        <button
                          className="link-s"
                          onClick={() => handleDeleteOption(option, value)}
                        >
                          x
                        </button>
                      </div>
                    )
                )
            )}

            {selectedMarques.map(
              (
                el,
                index // Add index as key
              ) => (
                <div className="selectedFilter col-auto mt-24 mr-16">
                  {el?.name}{" "}
                  <button
                    className="link-s"
                    onClick={() => handleDeleteMarque(el?.name)}
                  >
                    x
                  </button>
                </div>
              )
            )}
          </div>
          <div className="col-auto mt-16 text-end">
            <button className="link-s" onClick={resetFilters}>
              Tout effacer
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FilterOptionsWithSearch;
