import Logo from "../assets/imgs/LogoMarion.svg";
import Spin from "../assets/imgs/fi_map-pin.svg";
import Heart from "../assets/imgs/fi_heart.svg";
import Cart from "../assets/imgs/fi_shopping-cart.svg";
import User from "../assets/imgs/user.svg";
import ConnectedUser from "../assets/imgs/connectedUser.svg";
import All from "../assets/imgs/fi_arrow-left-bleu.svg";
import Active from "../assets/imgs/ActiveCat.svg";
import "../assets/css/header.scss";
import Services from "../services/global.service";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ModalAdr from "./modalAdr";
import AccessibleOrNot from "./zoneAccessibleOrNot";
import { jwtDecode } from "jwt-decode";
import { useMyContext } from "../context";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
const Header = () => {
  const navigate = useNavigate();

  const [metiers, setMetiers] = useState([]);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [accessibleModal, setAccessibleModal] = useState(false);
  const [connected, setConnected] = useState(false);
  const [check, setCheck] = useState(false);
  const [selectedMetier, setSelectedMetier] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalAdr, setModalAdr] = useState(false);
  const [cart, setCart] = useState();
  const [myAddress, setMyAddress] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerms, setSearchTerms] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const { userData, cartData } = useMyContext();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const myAdr = userData?.user_adresses?.filter(
    (address) => address.default == 1 && address.type == 1
  )[0]?.adresse;
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const submenuRef = useRef(null);
  const searchSuggestionsRef = useRef(null);
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const handleClickMetier = (metier) => {
    setSelectedMetier(metier);
    setShowSubmenu(!showSubmenu);
    setSelectedCategory(metier.subcategories[0]);
  };
  useEffect(() => {
    if (location.pathname === "/") {
      // If on the '/' route, set modalAdr based on localStorage
      const isModalAdrEmpty =
        localStorage.getItem("adr") === "" || !localStorage.getItem("adr");
      setModalAdr(isModalAdrEmpty);
    } else {
      // If not on the '/' route, set modalAdr to false
      setModalAdr(false);
    }
  }, [location?.pathname]); // Run this effect whenever the pathname changes
  // Effect to listen for clicks on the document body
  useEffect(() => {
    const handleClickOutsideSearchSuggestions = (event) => {
      if (
        searchSuggestionsRef?.current &&
        !searchSuggestionsRef?.current?.contains(event.target)
      ) {
        // Click occurred outside the search suggestion div, so hide the suggestions
        setSearchVisible(false);
      }
    };

    // Add event listeners when the component mounts
    document.addEventListener("mousedown", handleClickOutsideSearchSuggestions);

    // Remove event listeners when the component unmounts
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideSearchSuggestions
      );
    };
  }, [searchSuggestionsRef]); // Include searchSuggestionsRef as a dependency to ensure it updates correctly

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const handleClickCategory = (category) => {
    setSelectedCategory(category);
  };

  const handleClickOutside = (event) => {
    if (submenuRef?.current && !submenuRef?.current?.contains(event.target)) {
      setShowSubmenu(false);
      setSelectedMetier(null);
    }
  };
  const chooseAdr = () => {
    setModalAdr(!modalAdr);
  };
  const getMyAdresses = () => {
    Services.getOne(localStorage.getItem("id"), "adresses")
      .then((res) => {
        setMyAddress(
          res?.data?.filter(
            (address) => address?.default == 1 && address?.type == 1
          )
        );
        const livAdr = res?.data?.filter(
          (address) => address?.default == 1 && address?.type == 1
        );
        if (livAdr.length > 0) {
          localStorage.setItem(
            "adr",
            res?.data?.filter(
              (address) => address?.default == 1 && address?.type == 1
            )[0]?.adresse
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const auth = localStorage.getItem("token");
    if (auth) {
      getMyAdresses();
      getMyCart();
      const decodedToken = jwtDecode(auth);
      const currentTime = Date.now() / 1000;
      setConnected(decodedToken.exp < currentTime);
    } else {
      return setConnected(true);
    }
    Services.getAll("categories")
      .then((res) => {
        setMetiers(res?.data);
      })
      .catch((err) => {
        console.error("Error fetching metiers:", err);
      });

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [connected]);

  useEffect(() => {
    // Fetch metiers from API
    Services.getAll("categories")
      .then((res) => {
        setMetiers(res.data);
      })
      .catch((err) => {
        console.error("Error fetching metiers:", err);
      });
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const handleSearch = (value) => {
  //   setSearchTerm(value); // Store the current input value
  //   if (value.length > 2) {
  //     Services.create({ search_term: value }, "products/search")
  //       .then((res) => {
  //         setSearchTerms(res.data); // Assuming res.data is an array of search terms
  //         setSearchVisible(true);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  const handleSearch = (value) => {
    setSearchTerm(value); // Store the current input value
    if (value.length > 2) {
      Services.create({ search_term: value }, "products/search")
        .then((res) => {
          const limitedSuggestions = {
            ...res.data,
            products: res.data?.products?.slice(0, 8),
            categories: res.data?.categories?.slice(0, 3),
            marques: res.data?.marques?.slice(0, 3),
          };
          setSearchTerms(limitedSuggestions);
          setSearchVisible(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  

  const handleButtonClick = () => {
    searchTerm &&
      navigate("/search", {
        state:  { searchTerm } ,
      });
      setSearchVisible(false);
  };
  const getMyCart = () => {
    Services.getAll("panier")
      .then((res) => {
        setCart(res.data?.panier);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {modalAdr ? (
        <ModalAdr
          cart={cart}
          modalAdr={modalAdr}
          setModalAdr={setModalAdr}
          check={check}
          setCheck={setCheck}
          setAccessibleModal={setAccessibleModal}
          accessibleModal={accessibleModal}
        />
      ) : (
        ""
      )}
      {accessibleModal ? (
        <AccessibleOrNot
          accessibleModal={accessibleModal}
          setAccessibleModal={setAccessibleModal}
          check={check}
          connected={connected}
        />
      ) : (
        ""
      )}

      <header>
        <div className="">
          {/* Bootstrap navbar implementation for mobile */}
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <Link className="navbar-brand mobile" to="/">
                <img src={Logo} alt="logo marion" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleNavbar}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse flex-column ${
                  isOpen ? "show" : ""
                }`}
              >
                <div className="row col-12 justify-content-between align-items-center">
                  <div className="col-auto px-0 desktop">
                    <Link className="navbar-brand" to="/">
                      <img src={Logo} alt="logo marion" />
                    </Link>
                  </div>
                  <div className="col-auto px-0 text-center ">
                    <button
                      className=" adr adr-hover m-0 d-flex align-items-center"
                      onClick={() => {
                        chooseAdr();
                      }}
                    >
                      <img src={Spin} alt="map icon" className="mr-8" />
                      {localStorage.getItem("adr") || myAdr ? (
                        <span className="adr-item">
                          {localStorage.getItem("adr") ?? myAdr}
                        </span>
                      ) : (
                        <span className="link-s">
                          Saisir mon adresse de livraison
                        </span>
                      )}
                    </button>
                  </div>
                  <div
                    className={`${
                      !connected ? "col-md-4 col-lg-4" : "col-md-4 col-lg-4"
                    } col-xs-12 px-0 text-center relative`}
                  >
                    <div className="search-bar d-flex align-items-center form-control">
                      {" "}
                      <input
                        //type="search"
                        className="form-control adr"
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                           onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleButtonClick(searchTerm);
                            }
                          }}
                        placeholder="Rechercher un produit, une référence, une marque ..."
                      />
                      <button
                        className="search-with-filtre"
                        type="submit"
                        onClick={() => handleButtonClick(searchTerm)}
                      >
                        <FontAwesomeIcon icon={faSearch} size="1x" />
                      </button>
                    </div>
                    {searchVisible && (
                      <div className="search-terms" ref={searchSuggestionsRef}>
                        {searchTerms?.products?.length > 0 ||
                        searchTerms?.categories.length > 0 ||
                        searchTerms?.marques?.length > 0 ? (
                          <div>
                            {searchTerms?.products?.length > 0 && (
                              <>
                                <p className="body-s">
                                  Suggestions de recherche
                                </p>
                                {searchTerms?.products?.map((product) => (
                                  <div key={product?.id} className="text-start">
                                    <a
                                      className="search-res"
                                      href={`/product/${product?.id}`}
                                    >
                                      {product?.titre}
                                    </a>
                                  </div>
                                ))}
                              </>
                            )}
                            {searchTerms.categories.length > 0 && (
                              <>
                                <p className="body-s mt-8">Catégories</p>
                                {searchTerms?.categories?.map((category) => (
                                  <div key={category.id} className="text-start">
                                    <a
                                      href={`/category/${category?.id}`}
                                      className="search-res"
                                    >
                                      {" "}
                                      {category?.name}
                                    </a>
                                  </div>
                                ))}
                              </>
                            )}
                            {searchTerms.marques.length > 0 && (
                              <>
                                <p className="body-s mt-8">Marques</p>
                                {searchTerms?.marques?.map((marque) => (
                                  <div key={marque?.id} className="text-start">
                                    <a
                                      className="search-res"
                                      href={`/products/marque/${marque?.id}`}
                                    >
                                      {marque?.name}
                                    </a>
                                  </div>
                                ))}
                              </>
                            )}
                            {searchTerm && (
                              <>
                                <span
                                  className="body-s"
                                  type="submit"
                                  onClick={() => handleButtonClick(searchTerm)}
                                >
                                  {" "}
                                  Voir tout{" "}
                                </span>
                              </>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-auto text-center px-0 d-flex">
                    {!connected ? (
                      <a href="/profil" className="link-s hover-link">
                        <img src={ConnectedUser} alt="User connected icon" />
                      </a>
                    ) : (
                      <div className="dropdown">
                        <button
                          className="toggle-menu dropdown-toggle d-flex align-items-center"
                          type="button"
                          id="dropdownMenuButton"
                          onClick={toggleDropdown}
                        >
                          <img src={User} alt="User icon" className="mr-8" />
                          <span className="f-16 bleu700">
                            Se connecter <br /> / S’inscrire
                          </span>
                        </button>
                        <div
                          className={`dropdown-menu drop-header ${
                            dropdownOpen ? "show" : ""
                          }`}
                          aria-labelledby="dropdownMenuButton"
                        >
                          <Link
                            to="/login"
                            className="cta-primary-s dropdown-item"
                          >
                            Se connecter
                          </Link>
                          <Link
                            to="/register"
                            className="cta-secondary-s mt-16 dropdown-item"
                          >
                            S'inscrire
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-md-auto col-xs-auto  px-0 col-sm text-end">
                    <a
                      className="adr adr-hover m-0 p-16 fit-content "
                      href="/panier"
                    >
                      <img
                        src={Cart}
                        alt="Cart icon"
                        className="grey-hr pr-8"
                      />
                      <span className="f-16 bleu800 ml-8">
                        {cartData?.panier_items?.length > 0
                          ? cartData?.panier_items?.reduce(
                              (total, item) => total + item?.quantity,
                              0
                            )
                          : cart?.panier_items?.length > 0
                          ? cart?.panier_items?.reduce(
                              (total, item) => total + item?.quantity,
                              0
                            )
                          : 0}
                      </span>
                    </a>
                  </div>
                </div>
                <div className="row col-12 mt-24">
                  {metiers.map((metier) => (
                    <div className="col-auto p-0" key={metier?.id}>
                      <p
                        className={`f-16 bleu700 metiers  link-cursor  ${
                          selectedMetier && selectedMetier?.id === metier?.id
                            ? "active-menu"
                            : ""
                        }`}
                        onClick={() => handleClickMetier(metier)}
                      >
                        {metier?.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      {showSubmenu && selectedMetier && (
        <div
          className={`sub-menu ${showSubmenu ? "open" : ""}`}
          ref={submenuRef}
        >
          <div className="row">
            <div className="col-md-4 bg-gris">
              {selectedMetier?.subcategories?.map((category) => (
                <p
                  key={category?.id}
                  onClick={() => handleClickCategory(category)}
                  className={`subtitle-m link-cursor bleu700 mb-24 ${
                    selectedCategory && selectedCategory.id === category?.id
                      ? "active d-flex"
                      : ""
                  }`}
                >
                  {selectedCategory && selectedCategory?.id === category?.id ? (
                    <img src={Active} alt="active icon" className="mr-8" />
                  ) : null}
                  {category?.name}
                </p>
              ))}
            </div>
            <div className="col right">
              <div className="text-start">
                {selectedCategory?.image && (
                  <img
                    src={IMG_URL + selectedCategory?.image}
                    alt="icon category"
                  />
                )}{" "}
                <a href={`/category/${selectedCategory.id}`}>
                  Tout voir <img src={All} alt="See all icon" />{" "}
                </a>
              </div>
              <div className="row mt-16">
                {selectedCategory &&
                  selectedCategory?.subcategories &&
                  selectedCategory?.subcategories?.map((subCategory) => (
                    <div
                      className="col-md-4 text-start pb-16"
                      key={subCategory?.id}
                    >
                      <a
                        className="sub-cat bleu700 link-cursor"
                        href={`/category/${subCategory?.id}`}
                      >
                        {subCategory?.name}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
