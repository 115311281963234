
import './App.scss';
import '../src/assets/css/ui.scss'
import '../src/assets/css/colors.scss'
import '../src/assets/css/btn.scss'
import moment from 'moment-timezone';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
//import Components
import Apropos from './views/auth/a-propos';
import Login from './views/auth/login';
import Register from './views/auth/register';
import ResetPassword from './views/auth/resetPassword';
import Home from './views/home/home';
import CatalogueCategory from './views/catalogue/catalogueCategory';
import Profil from './views/profil/profil';
import { MyProvider } from './context';
import AuthService from './services/auth.service';
import { useEffect, useState } from 'react';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoutes from './utils/ProtectedRoutes';
import DetailsProduct from './views/catalogue/detailsProduct';
import ProductByMarque from './views/catalogue/productByMarque';
import NotFoundRoute from './views/404';
import Panier from './views/panier';
import PasswordChang from './views/auth/changePassword';
import Livraison from './views/livraison';
import Paiement from './views/paiement';
import References from './views/home/refrences';
import Cgu from './views/footer/cgu';
import Legal from './views/footer/legal';
import Cgv from './views/footer/cgv';
import Politique from './views/footer/politique';
import ValidationOrder from './views/validationOrder';
import SearchResult from "./views/search/searchResult";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import SupplierOrder from './views/suppliers/SupplierOrder';
import ParisTime from './views/testTime';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyArQqKMu6FiVGFlddJA_SMWfDCBg71Bmiw",
  authDomain: "marion-materiaux.firebaseapp.com",
  projectId: "marion-materiaux",
  storageBucket: "marion-materiaux.appspot.com",
  messagingSenderId: "340883273714",
  appId: "1:340883273714:web:50619c86cf8ff1c0775d6b",
  measurementId: "G-2R6LTYZPBM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
moment.tz.setDefault("Europe/Paris");
  
  return (
    <div className="App">  
          <ToastContainer />
     <MyProvider>
        <BrowserRouter>
          <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/ParisTime" element={<ParisTime />} />
          <Route path="/Renitialise-mot-de-passe" element={<ResetPassword />} />
          <Route path="/changer-mot-de-passe" element={<PasswordChang />} />
          <Route path="/register" element={<Register />} />
          <Route path="/a-propos" element={<Apropos />} />
          <Route path="/404" element={<NotFoundRoute />} />
          <Route element={<ProtectedRoutes role="" />}> 
            <Route path="/profil" element={<Profil />} /> 
            <Route path="/profil/:id" element={<Profil />} /> 
            <Route path="/profil/:id/:idOrder" element={<Profil />} /> 
            <Route path="/commande/forunisseur" element={<SupplierOrder />} /> 
            <Route path="/panier" element={<Panier />} />
            <Route path="/livraison" element={<Livraison />} />
            <Route path="/paiement" element={<Paiement />} />
            <Route path="/validation" element={<ValidationOrder />} />
          </Route>
            <Route path="/" element={<Home />} />
            {/* <Route path="/category/:nv1/:nv2/:nv3/:id" element={<CatalogueCategory />} />
            <Route path="/category/:nv1/:nv2/:id" element={<CatalogueCategory />} />
            <Route path="/category/:nv1/:id" element={<CatalogueCategory />} /> */}
            <Route path="/category/:id" element={<CatalogueCategory />} />
            {/* <Route path=":nv1/:nv2/product/:id" element={<DetailsProduct />} /> */}
            <Route path="/search" element={<SearchResult />}  />
            <Route path="/product/:id" element={<DetailsProduct />} />
            <Route path="/products/marque/:id" element={<ProductByMarque />} />
            <Route path="/marques" element={<References />} />
            <Route path="/cgu" element={<Cgu/>} />
            <Route path="/legal" element={<Legal/>} />
            <Route path="/cgv" element={<Cgv/>} />
            <Route path="/politique" element={<Politique/>} />       
            <Route path="*" element={<NotFoundRoute />} />
          </Routes>
          </BrowserRouter>
        </MyProvider>
        {/* <NotFoundRoute /> */}
    </div>
  );
}

export default App;
