import React, { useEffect, useState } from "react";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import arrowUpDown from "../assets/imgs/arrowUpDown.svg";
import "../assets/css/datatable.scss";
import ReturnIcon from "../assets/imgs/fi_arrow-left.svg";
import download from "../assets/imgs/fi_download.svg";
import FiltreOrders from "./filtreCommandes";
import { Badge, Card } from "react-bootstrap";
import Truck from "../assets/imgs/fi_truck_status.svg";
import mapIcon from "../assets/imgs/fi_map-pin-orange.svg";
import Services from "../services/global.service";
import Visa from "../assets/imgs/visaSingle.svg";
import MasterCard from "../assets/imgs/masterCard.svg";

export default function Datatables({
  columns,
  data,
  setEndPoint,
  endPoint,
  pageSizeOptions = [5, 20, 30],
}) {
  const [tableData, setTableData] = useState(data);
  const [filterInput, setFilterInput] = useState("");
  const [lastPaiementCard, setLastPaiementCard] = useState("");
  const [commandeDetails, setCommandeDetails] = useState(null);
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0, pageSize: pageSizeOptions[0] },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setTableData(data);
  }, [data]);

  
  const handleFilterChange = (e) => {
    const value = e.target.value || "";


    // Filtrer les données en fonction de la valeur de recherche
    const filteredData = data.filter((item) => {
      return columns.some((column) => {
        if (typeof column.accessor === "string" && column.accessor !== "id") {
          const accessors = column.accessor.split(".");
          const nestedValue = accessors.reduce((acc, cur) => {
            if (acc && typeof acc === "object" && acc.hasOwnProperty(cur)) {
              return acc[cur];
            }
            return null;
          }, item);
          return (
            nestedValue &&
            nestedValue.toString().toLowerCase().includes(value.toLowerCase())
          );
        }
        return false;
      });
    });

   

    // Mettre à jour les données de la table avec les données filtrées
    setTableData(filteredData);

    // Mettre à jour le champ de recherche
    setFilterInput(value);
  };
  function formatStatus(status) {
    switch (status) {
      case "4":
        return "En cours de validation";
      case "1":
        return "En cours de livraison";
      case "3":
        return "En cours de préparation";
      case "2":
        return "Livré";
      case "5":
        return "Validé";
      case "6":
        return "Annulé";
      default:
        return status;
    }
  }
  const renderPageNumbers = () => {
    const maxPageButtons = 5;
    const currentPage = pageIndex + 1;
    const totalPages = pageOptions.length;
    const startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <strong
          key={i}
          className={`page-tab ${pageIndex === i - 1 ? "active" : ""}`}
          onClick={() => nextPage(i - 1)}
        >
          {i}
        </strong>
      );
    }
    return pageNumbers;
  };

  const getLastPayment = (id) => {
    Services.getAllByData(`lastpayment/${id}`, {})
      .then((res) => {
        setLastPaiementCard(res.data?.card);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //const [invoicesZipUrl, setInvoicesZipUrl] = useState(null); // State to hold the URL of the downloaded zip file

  const downloadZip = () => {
    Services.getAll('factures/mine/all')
      .then((res) => {
        // Assuming res.data contains the URL or data of the zip file
        // Assuming res.data is the URL to download the zip file
        window.location.href = res?.data?.download_link; // Store the URL in state to trigger a re-render and update the link
      })
      .catch((err) => {
        console.log(err);
        // Handle errors appropriately
      });
  };
  return (
    <div>
      {!commandeDetails ? (
        <div className="dynamic-table-container">
          <h5 className="bleu700 mt-40">Mes Commandes</h5>

          <div className="pt-2 pb-3 ">
            <div className=" w-50">
              <div className="form-floating mb-3">
                <input
                  type="search"
                  className="custom-search-input form-control input-bg-white"
                  placeholder=""
                  value={filterInput}
                  onChange={handleFilterChange}
                />
                <label htmlFor="floatingInput">
                  Rechercher un numéro de commande
                </label>
              </div>
            </div>
          </div>
          <FiltreOrders setEndPoint={setEndPoint} endPoint={endPoint} />
          <div className="mt-40 d-flex justify-content-between align-items-center">
            <span className="d-flex align-items-center pb-3">
              <p className="text-large px-2 my-0">Afficher</p>{" "}
              <select
                style={{ width: "70px" }}
                className="form-select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {pageSizeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <p className="text-large px-2 my-0">lignes</p>
            </span>
            <button onClick={downloadZip} className="link-s">
              <img src={download} alt="download icon" className="mr-8" />
              Télécharger toutes les factures
            </button>
          </div>
          <table {...getTableProps()} className="dynamic-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={
                        column.Header === "Adresse de livraison"
                          ? { width: "200px" }
                          : {}
                      }
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={arrowUpDown}
                            alt="Down Arrow"
                            width="15px"
                            style={{ marginLeft: "4px" }}
                          />
                        ) : (
                          <img
                            src={arrowUpDown}
                            alt="Up Arrow"
                            width="15px"
                            style={{ marginLeft: "4px" }}
                          />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr
                  className="link-cursor"
                    {...row.getRowProps()}
                    style={{
                      backgroundColor: rowIndex % 2 === 0 ? "white" : "#F2F5F8",
                    }}
                    onClick={() => {
                      getLastPayment(row.original.id_panier);
                      setCommandeDetails(row.original);
                      setEndPoint('commandes/filter?')

                    }}
                  >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="container mb-5 mt-40">
            <div className="row">
              <div className="col-7">
                <p className="h6">
                  Affichage de 1 à {pageSize} sur {pageOptions.length} lignes{" "}
                </p>
              </div>
              <div className="col d-flex justify-content-end align-items-center">
                <button
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                  className="btn btn-outline-primary CustomPagination"
                >
                  Précedent
                </button>
                {renderPageNumbers()}
                <button
                  onClick={nextPage}
                  disabled={!canNextPage}
                  className="btn btn-outline-primary CustomPagination"
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <h5 className="bleu700 mt-40 d-flex align-items-center">
            <button
              className="link-s"
              onClick={() => {
                setCommandeDetails(null);
              }}
            >
              {" "}
              <img src={ReturnIcon} alt="Icon retour" className="mr-8" />
            </button>{" "}
            Détail de la commande {commandeDetails?.reference}{" "}
          </h5>

          <div className="white-bg-card no-border text-start mt-32 p-24">
            <div className="row">
              <div className="col">
                <span className="badge-ongoing">
                  <img src={Truck} alt="truck icon" className="mr-8" />
                  {formatStatus(commandeDetails?.status)}{" "}
                </span>
              </div>
              <div className="col text-end">
                <a
                  className="link-s "
                  href={`${process.env.REACT_APP_BASE_URL}factures/${commandeDetails.id}/${localStorage.getItem('token')}`}
                  download
                >
                  <img src={download} alt="download icon" /> Télécharger la facture
                </a>{" "}
              </div>
            </div>
            <div className="mt-32">
              <div className="d-flex ">
                <div className=" mr-40">
                  {" "}
                  <label className="bleu400-body-s mr-8">
                    N° de commande :
                  </label>{" "}
                  <span className="Exergue">{commandeDetails?.reference}</span>{" "}
                </div>
                <div className="">
                  {" "}
                  <label className="bleu400-body-s mr-8">
                    Date de commande :{" "}
                  </label>{" "}
                  <span className="Exergue">
                    {new Date(
                      commandeDetails?.created_at
                    )?.toLocaleDateString()}
                  </span>{" "}
                </div>
              </div>
              <div className=" mt-24">
                {" "}
                <label className="bleu400-body-s mr-8">Acheteur : </label>
                <span className="Exergue">
                  {commandeDetails?.user?.nom} {commandeDetails?.user?.prenom}
                </span>{" "}
              </div>
            </div>
          </div>
          <div className="white-bg-card no-border text-start mt-24 p-24">
            <p className="bleu700 subtitle-m">Adresse de livraison</p>
            <div className="mt-32">
              <Card.Text className="ref-txt d-flex mt-32 ">
                <div className=" mr-8">
                  <img src={mapIcon} alt="map icon" className="mr-8" />
                </div>
                <div className="">
                  <p className="adr-name">
                    {commandeDetails?.adresse_user.name}
                  </p>
                  <p className="bleu600 subtitle-s mt-8">
                    {commandeDetails?.adresse_user.nom}{" "}
                    {commandeDetails?.adresse_user.prenom}
                  </p>
                  <p className="bleu600-body-s mt-8 mb-0">
                    {commandeDetails.adresse_user.adresse}
                  </p>
                  <p className="bleu600-body-s my-0">
                    {commandeDetails.adresse_user.telephone}
                  </p>
                </div>
              </Card.Text>
            </div>
          </div>
          <div className="white-bg-card no-border text-start mt-24 mb-24 p-24">
            <div className="d-flex justify-content-between">
              <p className="bleu700 subtitle-m">
                Mes articles ({commandeDetails?.panier?.panier_items.length})
              </p>
              <p className="bleu700 subtitle-m">{commandeDetails?.ttc} €</p>
            </div>

            <div id="my-articles ">
              {commandeDetails && commandeDetails.panier.panier_items
                ? commandeDetails?.panier?.panier_items.map((item) => (
                    <div className="mt-16" key={item.id}>
                      <React.Fragment>
                        <div className=" mt-8">
                          <div className="row mt-8">
                            <div
                              className="col-3 img-toggle"
                              style={{
                                backgroundImage: `url(${
                                  IMG_URL +
                                  item?.product_panier_item?.images[0]?.path
                                })`,
                              }}
                            ></div>
                            <div className="col-8">
                              <p className="bleu600-body-s">
                                {
                                  item?.product_panier_item?.product_marque
                                    ?.name
                                }
                              </p>
                              <p className="product-title">
                                {item?.product_panier_item?.titre}
                              </p>
                              <p className="legende">
                                Ref:{item?.product_panier_item?.reference}
                              </p>
                              <p className="body-m bleu800">
                                {(
                                  item?.product_panier_item?.ttc *
                                  +item?.quantity
                                ).toFixed(2).replace('.', ',')}{" "}
                                € TTC
                              </p>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    </div>
                  ))
                : null}
            </div>
          </div>
          <div className="white-bg-card no-border text-start mt-32 p-24 mb-24">
            <div className="row">
              <p className="bleu700 subtitle-m">Informations de paiement</p>
              <span>
                <div className="d-flex">
                  <div className="mr-8">
                    <img
                      src={
                        lastPaiementCard?.brand === "visa" ? Visa : MasterCard
                      }
                      alt="carte bleu"
                    />
                  </div>
                  <div>
                    <p className="bleu700 subtitle-m">Carte bleue</p>
                    <p className="bleu700-body-s py-0 my-0">
                      ****** {lastPaiementCard?.last4}
                    </p>
                  </div>
                </div>
              </span>
            </div>
          </div>
         
        </>
      )}
    </div>
  );
}
